<template>
  <UiLabel
    v-if="!isUserLoggedIn"
    :icons="{
      left: {
        name: 'account-outlined',
      },
    }"
    :text="`${$t('layout.header.logIn')}/${$t('layout.header.signUp')}`"
    class="nav-label body-m"
    color="white"
    :url="signInFullPath"
  />
  <UiDropdown v-else class="nav-dropdown">
    <UiLabel
      :icons="{
        left: {
          name: 'account-outlined',
        },
      }"
      :text="$t('layout.header.myAccount')"
      class="nav-label body-m"
      color="white"
    />
    <template #content>
      <div class="dropdown-item-container">
        <div class="dropdown-item header">
          <div v-if="firstName" class="greeting headline-m">
            {{ $t('layout.header.greeting', { name: firstName }) }}
          </div>
          <div v-if="renderLoyaltyLogo" class="beauty-pass-status">
            <UiSmartImage
              :src="`https://image-optimizer-reg.production.sephora-asia.net/static-images/${loyaltyTier}-tier-icon.png`"
              :alt="$t(`layout.header.${loyaltyTier}Tier`)"
              aspect-ratio="auto"
            />
          </div>
        </div>
        <span
          v-for="link in myAccountLinks"
          :key="link.to"
          class="dropdown-item body-m"
        >
          <UiLabel
            class="account-item"
            :text="link.text"
            :url="link.to"
            @click="link?.callback"
          />
        </span>
        <div class="divider-container">
          <UiDivider type="secondary" />
        </div>
        <span class="dropdown-item body-m">
          <UiLabel
            :text="$t('layout.header.logout')"
            :url="useRoute().fullPath"
            @click.prevent="signOut"
          />
        </span>
      </div>
    </template>
  </UiDropdown>
</template>

<script lang="ts" setup>
import UiLabel from '@sephora-asia/ui-label'
import UiDropdown from '@sephora-asia/ui-dropdown'
import UiDivider from '@sephora-asia/ui-divider'
import UiSmartImage from '@sephora-asia/ui-smart-image'
import type { ConfigDTO } from '~/types/api/bff/web/layout/layout.types'

const { t } = useI18n()
const { trackEvent } = useGtmTrack()
const { signInFullPath } = useBaseRoutes()

const props = defineProps<{
  config?: ConfigDTO
  firstName?: string
  loyaltyTier?: string
  isUserLoggedIn: boolean
}>()

const { signOut } = useAuth()

const myAccountLinks = computed(() => {
  const links: { text: string; to: string; callback?: () => void }[] = [
    {
      text: t('layout.header.dashboard'),
      to: '/account/dashboard',
    },
    {
      text: t('layout.header.orders'),
      to: '/account/orders',
    },
    {
      text: t('layout.header.getItAgain'),
      to: '/account/get-it-again',
    },
    {
      text: t('layout.header.reviews'),
      to: '/account/reviews',
    },
    {
      text: t('layout.header.wishList'),
      to: '/wishlist',
    },
    {
      text: t('layout.header.waitList'),
      to: '/waitlists',
    },
  ]

  if (props.config?.enableBeautyProfile) {
    links.push({
      text: t('layout.header.beautyProfile'),
      to: '/account/beauty-profile',
      callback: trackClickBeautyProfileEvent,
    })
  }

  return links
})

function trackClickBeautyProfileEvent() {
  trackEvent({
    event: 'beautyprofile_click_entry',
  })
}

const renderLoyaltyLogo = computed(() =>
  ['white', 'black', 'gold'].includes(props?.loyaltyTier || '')
)
</script>

<style lang="scss" scoped>
.nav-label {
  &:hover {
    color: var(--color-base-white);
    cursor: pointer;

    :deep(.icon-container) {
      svg {
        cursor: pointer;
        fill: var(--color-base-white) !important;
      }
    }
  }
}

.nav-dropdown {
  .dropdown-item-container {
    display: flex;
    width: 300px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    .dropdown-item.header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .beauty-pass-status {
        display: flex;
        height: 24px;
      }
    }

    .dropdown-item .account-item {
      cursor: pointer;
    }

    .divider-container {
      width: 100%;
    }
  }

  :deep(.dropdown-menu) {
    padding-top: 8px;
  }
}
</style>
