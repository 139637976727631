<template>
  <div class="sidebar-item-container">
    <UiLabel
      v-if="!isUserLoggedIn"
      :icons="{
        left: {
          name: 'account-outlined',
        },
      }"
      :text="`${$t('layout.header.logIn')}/${$t('layout.header.signUp')}`"
      class="icon-label sidebar-item body-m"
      :url="signInFullPath"
    />
    <UiAccordion v-else :has-toggle-icon="true">
      <template #header>
        <UiLabel
          :icons="{
            left: {
              name: 'account-outlined',
            },
          }"
          :text="$t('layout.header.myAccount')"
          class="icon-label body-m"
        />
      </template>
      <template #content>
        <div class="accordion-items-container">
          <span
            v-for="link in myAccountLinks"
            :key="link.to"
            class="accordion-item body-m"
          >
            <UiLabel
              class="sidebar-item"
              :text="link.text"
              :url="link.to"
              @click="link?.callback"
            />
          </span>
        </div>
      </template>
    </UiAccordion>
  </div>
</template>

<script lang="ts" setup>
import UiLabel from '@sephora-asia/ui-label'
import UiAccordion from '@sephora-asia/ui-accordion'
import type { ConfigDTO } from '~/types/api/bff/web/layout/layout.types'

const { t } = useI18n()
const { trackEvent } = useGtmTrack()
const { signInFullPath } = useBaseRoutes()

const props = defineProps<{
  config?: ConfigDTO
  isUserLoggedIn?: boolean
}>()

const myAccountLinks = computed(() => {
  const links: { text: string; to: string; callback?: () => void }[] = [
    {
      text: t('layout.header.dashboard'),
      to: '/account/dashboard',
    },
    {
      text: t('layout.header.orders'),
      to: '/account/orders',
    },
    {
      text: t('layout.header.getItAgain'),
      to: '/account/get-it-again',
    },
    {
      text: t('layout.header.reviews'),
      to: '/account/reviews',
    },
    {
      text: t('layout.header.wishList'),
      to: '/wishlist',
    },
    {
      text: t('layout.header.waitList'),
      to: '/waitlists',
    },
  ]

  if (props.config?.enableBeautyProfile) {
    links.push({
      text: t('layout.header.beautyProfile'),
      to: '/account/beauty-profile',
      callback: trackClickBeautyProfileEvent,
    })
  }

  return links
})

function trackClickBeautyProfileEvent() {
  trackEvent({
    event: 'beautyprofile_click_entry',
  })
}
</script>

<style lang="scss" scoped>
.icon-label {
  :deep(.icon-container) {
    margin-right: 12px;
  }
}

.sidebar-item-container {
  .sidebar-item,
  :deep(.accordion-header) {
    padding: 12px 20px;
    cursor: pointer;
  }

  .accordion-items-container {
    display: flex;
    flex-direction: column;

    .accordion-item {
      padding-left: 20px;
    }
  }
}
</style>
